import { defineStore } from 'pinia'
import { ref } from 'vue'
import { http } from '@/helpers/http'
import { useIDBKeyval } from '@vueuse/integrations/useIDBKeyval'
import type { TTagHint, TNiche, TVariantsWithNicheImage } from '@/types/tags'
import type { TDropdownOption } from '@/components/form/DesDropdown.vue'

export const useTagsStore = defineStore('tags', () => {
  const hints = ref<TTagHint[]>([])
  const niches = ref<TNiche[]>([])
  const variantsWithNicheImages = ref<TVariantsWithNicheImage[]>([])
  const nicheDropdownOptions = ref<TDropdownOption[]>([])

  let fetched: boolean = false
  let nichesFetched: boolean = false

  async function fetchTags() {
    if (fetched) {
      return
    }
    try {
      const res = await http.post<TTagHint[]>('/tags/hints')
      if (!res) throw new Error('Failed to fetch themes')
      hints.value = res
      fetched = true
    } catch {
      hints.value = []
    }
  }

  async function fetchVariantNicheImages(templateId: number): Promise<void> {
    try {
      variantsWithNicheImages.value =
        (await http.post<TVariantsWithNicheImage[]>('/tags/variant_niche_images', { templateId })) ?? []
    } catch {
      throw new Error('Failed to fetch variant niche images')
    }
  }

  async function getDataFromLocalStorageOrDb(key: string, templateId?: number): Promise<void> {
    const keyToUse = templateId ? key + '_' + templateId : key
    const { data: dataIDB, set: setIDB } = useIDBKeyval<string>(keyToUse, '')

    try {
      if (!dataIDB.value?.length) {
        if (key === 'hints') {
          await fetchTags()
          setIDB(JSON.stringify(hints.value))
        } else if (key === 'niches') {
          await fetchNiches()
          setIDB(JSON.stringify(niches.value))
        } else if (key === 'variantNicheImages' && templateId) {
          await fetchVariantNicheImages(templateId)
          setIDB(JSON.stringify(variantsWithNicheImages.value))
        }
      } else {
        const parsedData = JSON.parse(dataIDB.value)
        if (key === 'hints') {
          hints.value = parsedData
        } else if (key === 'niches') {
          niches.value = parsedData
          nicheDropdownOptions.value = niches.value
            .filter((niche) => niche?.tag_images.length > 0)
            .map((niche) => ({
              name: niche.name,
              label: niche.name,
            }))
        } else if (key === 'variantNicheImages') {
          variantsWithNicheImages.value = parsedData
        }
      }
    } catch (error) {
      console.error('Error fetching or parsing data:', error)
    }
  }

  async function fetchNiches() {
    if (nichesFetched) {
      return
    }
    try {
      niches.value = await http.get<TNiche[]>('/tags/get_templates_tags_with_images')
      nicheDropdownOptions.value = niches.value
        .filter((niche) => niche?.tag_images.length > 0)
        .map((niche) => ({
          name: niche.name,
          label: niche.name,
        }))
      nichesFetched = true
    } catch {
      throw new Error('Failed to fetch niches')
    }
  }

  return {
    hints,
    niches,
    variantsWithNicheImages,
    nicheDropdownOptions,
    fetchTags,
    fetchVariantNicheImages,
    getDataFromLocalStorageOrDb,
  }
})
