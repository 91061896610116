<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import { track } from '@/helpers/mixpanelDes'
  import { useUserStore } from '@/stores/user'
  import { ref, computed } from 'vue'
  import DesHeading from '@/components/DesHeading.vue'
  import DesButton from '@/components/button/DesButton.vue'

  const user = useUserStore()
  const router = useRouter()
  const bannerNumberToDisplay = ref<number>(getBannerNumberToDisplay(user.user.id))

  function getBannerNumberToDisplay(id: number): number {
    return (id - 1) % 4
  }

  interface TBannerConfig {
    topText: string
    bottomText: string
    imageSrc: string
    linkInsteadButton: boolean
    whiteNewLabel: boolean
  }

  const bannerConfig: TBannerConfig[] = [
    {
      topText: 'Power up your eBook creation,',
      bottomText: 'enhanced by AI with',
      imageSrc: '/dashboard/banners/banner_home_1.png',
      linkInsteadButton: false,
      whiteNewLabel: false,
    },
    {
      topText: 'Meet your AI-powered',
      bottomText: 'eBook creator',
      imageSrc: '/dashboard/banners/banner_home_1.png',
      linkInsteadButton: true,
      whiteNewLabel: false,
    },
    {
      topText: 'Meet your AI-powered',
      bottomText: 'eBook creator',
      imageSrc: '/dashboard/banners/banner_home_2.png',
      linkInsteadButton: false,
      whiteNewLabel: true,
    },
    {
      topText: 'Meet your AI-powered eBook creator',
      bottomText: '',
      imageSrc: '/dashboard/banners/banner_home_3.png',
      linkInsteadButton: false,
      whiteNewLabel: true,
    },
  ]

  function redirectAndTrackBannerClick() {
    trackBannerClick()
    router.push('/creator/wordgenie')
  }

  function trackBannerClick() {
    track('banner-clicked', {
      name: 'Wordgenie',
      label: bannerLabel.value,
      bannerIndex: bannerNumberToDisplay.value + 1,
    })
  }

  const bannerToDisplay = computed(() => {
    return bannerConfig[bannerNumberToDisplay.value]
  })

  const bottomTextPresent = computed(() => {
    return !!bannerToDisplay?.value?.bottomText?.length
  })

  const bannerLabel = computed(() => {
    if (!bannerToDisplay.value) {
      return ''
    } else if (bottomTextPresent.value) {
      return bannerToDisplay.value.topText + ' ' + bannerToDisplay.value.bottomText
    }
    return bannerToDisplay.value.topText
  })
</script>

<template>
  <div class="relative py-16 px-6 md:px-20 bg-black/30 rounded-xl flex overflow-hidden" :class="$style.gradient">
    <div class="absolute inset-y-0 right-0 flex justify-end">
      <img
        :src="bannerToDisplay.imageSrc"
        class="h-full max-w-none"
        :class="{ 'min-w-[1640px]': bannerNumberToDisplay === 3, 'min-w-[1620px]': bannerNumberToDisplay === 2 }"
        alt=""
      />
    </div>
    <div class="relative text-white flex flex-col gap-3 w-full items-start">
      <div class="flex gap-3 w-full items-start" :class="bannerNumberToDisplay === 3 ? 'flex-row' : 'flex-col'">
        <div
          :class="{
            'bg-blue-50': !bannerToDisplay.whiteNewLabel,
            'text-blue-50': bannerToDisplay.whiteNewLabel,
            'bg-white': bannerToDisplay.whiteNewLabel,
            'mt-4': bannerNumberToDisplay === 2,
          }"
          class="px-4 rounded-full text-xs font-medium"
        >
          NEW
        </div>
        <DesHeading
          :level="bannerToDisplay.whiteNewLabel ? '4' : '3'"
          :class="bannerToDisplay.whiteNewLabel ? `bg-clip-text text-transparent ${$style.gradientText}` : ''"
        >
          {{ bannerToDisplay?.topText }}
          <br v-if="bottomTextPresent" />
          <span v-if="bottomTextPresent">
            {{ bannerToDisplay.bottomText }}
          </span>
        </DesHeading>
      </div>
      <DesHeading level="2" :bold="bannerToDisplay.whiteNewLabel" class="sm:text-3xl">
        Wordgenie
        <span class="text-xl align-top">&trade;</span>
      </DesHeading>

      <DesHeading
        level="4"
        :class="{
          'mt-2': bannerNumberToDisplay === 3,
          'mt-8': bannerNumberToDisplay === 2,
          'mb-6': bannerNumberToDisplay === 2,
          'mt-16': !bannerToDisplay.whiteNewLabel,
        }"
      >
        <DesButton v-if="!bannerToDisplay.linkInsteadButton" @click="redirectAndTrackBannerClick()">
          Try it now
          <des-icon icon="fa-light fa-sparkles" />
        </DesButton>
        <RouterLink v-if="bannerToDisplay.linkInsteadButton" @click="trackBannerClick()" to="/creator/wordgenie">
          Try it now
          <des-icon icon="fa-light fa-arrow-right" />
        </RouterLink>
      </DesHeading>
    </div>
  </div>
</template>

<style scoped module>
  .gradient {
    background: linear-gradient(180deg, #2f659a 0%, #000 0.01%, #002743 89.58%, #00264b 100%);
  }
  .gradientText {
    background-image: linear-gradient(to right, #97c2fa, #d2c9e7);
  }
</style>
