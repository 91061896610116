<script setup lang="ts">
  import { useModalStore } from '@/stores/modal'
  import { watch } from 'vue'
  const modal = useModalStore()

  watch(
    () => modal.modals.length,
    () => {
      document.body.focus()
    },
  )
</script>

<template>
  <div
    class="fixed z-50 inset-0 bg-black-10/25 transition-opacity"
    :class="{ 'opacity-0 pointer-events-none': !modal.modals?.length }"
    data-modal
  >
    <div
      v-for="(instance, key) in modal.modals"
      :key="key"
      :class="{ 'opacity-0 pointer-events-none': modal.modals?.length - 1 !== key }"
      class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-opacity"
    >
      <component :is="instance.component" :closeModal="modal.close" :init="instance.init" />
    </div>
  </div>
</template>
